// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-defalink-jsx": () => import("./../../../src/pages/defalink.jsx" /* webpackChunkName: "component---src-pages-defalink-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pos-jsx": () => import("./../../../src/pages/pos.jsx" /* webpackChunkName: "component---src-pages-pos-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-prositos-app-jsx": () => import("./../../../src/pages/prositos-app.jsx" /* webpackChunkName: "component---src-pages-prositos-app-jsx" */),
  "component---src-pages-prositos-jsx": () => import("./../../../src/pages/prositos.jsx" /* webpackChunkName: "component---src-pages-prositos-jsx" */)
}

